import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
/**
 * Information card : Title / Description / Image, click handler
 */
const InformationCard = ({ onClick, selected, element }) => {
	return (
		<MDBox
			m={1}
			mt={4}
			className="cardGraphChoice"
			onClick={onClick}
			p={3}
			borderRadius="xl"
			style={{
				backgroundColor: selected ? "#1A73E8" : ""
			}}
			sx={{
				transition: "background-color 0.1s ease-in-out",
				"&:hover": {
					backgroundColor: "#eeeeee"
				}
			}}
		>
			<MDBox display="flex">
				<MDBox flex="1">
					<MDTypography variant="h3" color={selected ? "white" : "dark"}>
						{i18n.t(element.name)}
					</MDTypography>
					<MDTypography variant="body2" color={selected ? "white" : "dark"}>
						{i18n.t(element.description)}
					</MDTypography>
				</MDBox>
				{element.image && (
					<MDBox
						flex="3"
						position="relative"
						borderRadius="lg"
						mx={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<MDBox
							component="img"
							src={element.image}
							borderRadius="lg"
							shadow="md"
							width="auto"
							height="100%"
							position="relative"
							zIndex={1}
							maxHeight="30vh"
						/>
						<MDBox
							borderRadius="lg"
							shadow="md"
							width="100%"
							height="100%"
							position="absolute"
							left={0}
							top="3%"
							sx={{
								backgroundImage: `url(${element.image})`,
								transform: "scale(0.94)",
								filter: "blur(12px)",
								backgroundSize: "cover"
							}}
						/>
					</MDBox>
				)}
			</MDBox>
		</MDBox>
	);
};

export default InformationCard;
